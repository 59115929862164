export default {
  WELCOME__SCREEN: 'welcome',
  PURPOSE__SCREEN: 'purpose',
  HOUSE_TYPE__SCREEN: 'houseType',
  NUMBER_OF_UNITS__SCREEN: 'numberOfUnits',
  NUMBER_OF_BUILDINGS__SCREEN: 'numberOfBuildings',
  EXPLANATION__SCREEN: 'explanation',
  UNIT_SIZE__SCREEN: 'unitSize',
  NUMBER_OF_FLOORS__SCREEN: 'numberOfFloors',
  FOOTPRINT__SCREEN: 'footprint',
  COUNTRY__SCREEN: 'country',
  LOADER__SCREEN: 'loader',
  CHARTS__SCREEN: 'charts'
};
