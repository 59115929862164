import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Loader.css';

const loader = b.with('loader');

const Loader = ({ text = '', ready = false }) => (
  <div className={loader()}>
    <p className={loader('text')}>{text}</p>
    <div className={loader('spinner', { ready })}>
      <div className={loader('check', { ready })} />
    </div>
  </div>
);

Loader.propTypes = {
  text: PropTypes.string,
  ready: PropTypes.bool
};

export default Loader;
