import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChartJs from 'chart.js';
import b from 'b_';

import CHART_OPTIONS from './ChartOptions';

import './Chart.css';

const chart = b.with('chart');

const Chart = ({ labels, datasets }) => {
  const canvas = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const myChartRef = canvas.current.getContext('2d');

    chartInstance.current = new ChartJs(myChartRef, CHART_OPTIONS);
  }, []);

  useEffect(() => {
    const max = [0, 0, 0, 0];

    chartInstance.current.data.labels = labels;
    chartInstance.current.data.datasets = datasets;

    datasets.forEach(item => {
      item.data.forEach((value, index) => {
        max[index] = max[index] + value; // eslint-disable-line operator-assignment
      });
    });

    const CHART_MAX_VALUE = Math.max(...max);

    chartInstance.current.options.scales.yAxes[0].ticks.max = CHART_MAX_VALUE;
    chartInstance.current.options.scales.yAxes[0].ticks.stepSize = CHART_MAX_VALUE / 2;

    chartInstance.current.update();

    chartInstance.current.options.animation = false;
  }, [labels, datasets]);

  return (
    <div className={chart()}>
      <canvas id="chart" ref={canvas} />
    </div>
  );
};

Chart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  ).isRequired
};

export default Chart;
