import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen } from '../../../redux/selectors';

import './Slider.css';

const slider = b.with('slider');

function validateValueByStep(value, step) {
  return value % step === 0;
}

function getCorrectValue(value, step) {
  const rest = value % step;

  return value + (step - rest);
}

function validate(value, min, max, step) {
  if (value <= 0) {
    return min;
  }

  if (value >= 1000) {
    return max;
  }

  if (!validateValueByStep(value, step)) {
    return getCorrectValue(value, step);
  }

  return value;
}

const Slider = ({
  onChange,
  min = 1,
  max = 100,
  value,
  def = min,
  unit = '',
  prefix = '',
  range = true,
  idPrefix,
  step = 1
}) => {
  const activeScreen = useSelector(selectActiveScreen);
  const inputEl = useRef(null);
  const id = idPrefix === activeScreen ? activeScreen : `${activeScreen}-${idPrefix}`;
  const handleChange = useCallback(
    e => {
      const result = validate(Number(e.currentTarget.value), min, max, step);

      inputEl.current.value = result;

      onChange(result);
    },
    [max, min, onChange, step]
  );
  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleChange(e);
      }
    },
    [handleChange]
  );

  useEffect(() => {
    if (value === undefined) {
      onChange(def);
    }
  }, [def, onChange, value]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    const result = validate(Number(value), min, max, step);

    if (result !== value) {
      inputEl.current.value = result;

      onChange(result);
    }
  }, [max, min, onChange, step, value]);

  return (
    <div className={slider()}>
      <span className={slider('value')}>
        {prefix}
        <input
          className={slider('input')}
          type="number"
          defaultValue={value}
          ref={inputEl}
          onBlur={handleChange}
          step={step}
          onKeyPress={handleKeyPress}
          id={`${id}-input`}
        />
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: unit }} />
      </span>
      {range ? (
        <input
          className={slider('range')}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value || def}
          onChange={handleChange}
          id={`${id}-slider`}
        />
      ) : null}
    </div>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  idPrefix: PropTypes.string.isRequired,
  unit: PropTypes.string,
  prefix: PropTypes.string,
  def: PropTypes.number,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  range: PropTypes.bool,
  step: PropTypes.number
};

export default Slider;
