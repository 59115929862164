import React, { useState } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import { getIsChartScreen } from '../../../redux/selectors';

import SidebarButton from './SidebarButton';
import ControlPanel from './ControlPanel';

import './Sidebar.css';

const sidebar = b.with('sidebar');

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const isChartScreen = useSelector(getIsChartScreen);

  if (!isChartScreen) {
    return null;
  }

  return (
    <div className={sidebar({ open })}>
      <div className={sidebar('content', { open })}>
        <ControlPanel />
      </div>
      <div className={sidebar('button', { open })}>
        <SidebarButton
          onClick={() => {
            setOpen(!open);
          }}
          open={open}
        />
      </div>
    </div>
  );
};

export default Sidebar;
