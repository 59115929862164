import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';

import configureStore from '../store';
import App from '../App';

const Index = ({ store }) => (
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);

Index.propTypes = {
  store: PropTypes.shape({}).isRequired
};

function initGTM(gtmId) {
  const tagManagerArgs = {
    gtmId
  };

  TagManager.initialize(tagManagerArgs);
}

export default function index(ssr, initialState, gtmId) {
  const root = document.getElementById('root');
  const store = configureStore(initialState);

  if (process.env.NODE_ENV === 'production' && gtmId) {
    initGTM(gtmId);
  }

  if (ssr) {
    ReactDOM.hydrate(<Index store={store} />, root);
  } else {
    ReactDOM.render(<Index store={store} />, root);
  }
}
