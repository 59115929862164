import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchHousesSuccess } from '../redux/actions';
import { FETCH_HOUSES } from '../redux/constants';
import api from '../helpers/api';

export function* fetchHousesSaga() {
  const { result } = yield call(api.fetchHouses);

  if (result) {
    yield put(fetchHousesSuccess(result));
  }
}

export default function*() {
  yield takeLatest(FETCH_HOUSES, fetchHousesSaga);
}
