import { createSelector } from 'reselect';

import {
  selectChartsSettings,
  selectHouses,
  getSelectedFootprint,
  selectSelectedHouseType,
  selectSelectedNumberOfFloors
} from './common';

const getHouseTypeRank = (types, type) => {
  return types.includes(type) ? 0 : 1;
};

export const getHousesByHouseTypeRank = createSelector(selectSelectedHouseType, selectHouses, (houseType, houses) => {
  return houses.reduce((result, item) => {
    // eslint-disable-next-line no-param-reassign
    result[item._id] = getHouseTypeRank(item.types, houseType);

    return result;
  }, {});
});

const getNumberOfFloorsRank = (numberOfFloors, value) => {
  if (typeof numberOfFloors === 'number') {
    return Math.abs(value - numberOfFloors);
  }

  const [min, max] = numberOfFloors.split('-');

  if (value >= min && value <= max) {
    return 0;
  }

  return Math.abs(value - max);
};

export const getHousesByNumberOfFloorsRank = createSelector(
  selectSelectedNumberOfFloors,
  selectHouses,
  (numberOfFloors, houses) => {
    return houses.reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result[item._id] = getNumberOfFloorsRank(item.numberOfFloors, numberOfFloors);

      return result;
    }, {});
  }
);

const getFootprintRank = (num, value) => {
  return Math.abs(num - value);
};

export const getHousesByFootprintRank = createSelector(getSelectedFootprint, selectHouses, (footprint, houses) => {
  return houses.reduce((result, item) => {
    // eslint-disable-next-line no-param-reassign
    result[item._id] = getFootprintRank(item.depth * item.width, footprint);

    return result;
  }, {});
});

function comparator(a, b) {
  if (a.houseTypeRank > b.houseTypeRank) return 1;
  if (a.houseTypeRank < b.houseTypeRank) return -1;

  if (a.floorsRank > b.floorsRank) return 1;
  if (a.floorsRank < b.floorsRank) return -1;

  return a.footprintRank - b.footprintRank;
}

export const getHousesByRank = createSelector(
  getHousesByHouseTypeRank,
  getHousesByNumberOfFloorsRank,
  getHousesByFootprintRank,
  selectHouses,
  (houseTypeRanks, floorsRanks, footprintRank, houses) => {
    return houses
      .map(house => ({
        ...house,
        houseTypeRank: houseTypeRanks[house._id],
        floorsRank: floorsRanks[house._id],
        footprintRank: footprintRank[house._id]
      }))
      .sort(comparator);
  }
);

export const getAvailableHouses = createSelector(selectChartsSettings, getHousesByRank, (chartSettings, list) => {
  const { title, count } = chartSettings.houses;

  return { title, list: list.slice(0, count) };
});
