import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { getTotalSize } from '../../../redux/selectors';

import './TotalSize.css';

const totalSizeClass = b.with('total-size');

const TotalSize = ({ message }) => {
  const totalSize = useSelector(getTotalSize);

  return (
    <p className={totalSizeClass()}>
      {message}:{' '}
      <span className={totalSizeClass('value')}>
        {totalSize}m<sup>2</sup>
      </span>
    </p>
  );
};

TotalSize.propTypes = {
  message: PropTypes.string.isRequired
};

export default TotalSize;
