import React from 'react';

import { Button, Description, Explanation, OptionList, Title } from '../../Atoms';
import SCREENS from '../../../helpers/screens';
import { useSelectedOptions, useSetScreen, useSettings, useIsB2C } from '../../../helpers/hooks';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';

const PurposeScreen = () => {
  const [purpose, selectPurpose] = useSelectedOptions(SCREENS.PURPOSE__SCREEN);
  const { title, message, options, explanation, prevButton, nextButton, additional } = useSettings(
    SCREENS.PURPOSE__SCREEN
  );
  const setPrevScreen = useSetScreen(SCREENS.EXPLANATION__SCREEN);
  const setNextScreen = useSetScreen(SCREENS.HOUSE_TYPE__SCREEN);
  const isB2C = useIsB2C();

  return (
    <Screen>
      <ScreenContent>
        <Title text={title} />
        <OptionList onSelect={selectPurpose} options={options} value={purpose} />
        <Description text={isB2C ? additional.message : message} />
        {explanation ? <Explanation title={explanation.title} message={explanation.message} /> : null}
      </ScreenContent>
      <ScreenButtons>
        {isB2C ? (
          <Button text={additional.button} href={additional.link} link id="b2c-link" />
        ) : (
          <>
            <Button onClick={setPrevScreen} text={prevButton} outline id="prev-btn" />
            <Button onClick={setNextScreen} text={nextButton} disabled={!purpose} id="next-btn" />
          </>
        )}
      </ScreenButtons>
    </Screen>
  );
};

export default PurposeScreen;
