import React from 'react';

import { useSelectedOptions, useSettings } from '../../../helpers/hooks';
import SCREENS from '../../../helpers/screens';
import { HouseTypeOptionList } from '../../Atoms';

const SCREEN = SCREENS.HOUSE_TYPE__SCREEN;

const HouseTypeControl = () => {
  const [houseType, selectHouseType] = useSelectedOptions(SCREEN);
  const { options } = useSettings(SCREEN);

  return <HouseTypeOptionList onSelect={selectHouseType} options={options} value={houseType} />;
};

export default HouseTypeControl;
