import React from 'react';
import b from 'b_';

import { useHouses } from '../../../../helpers/hooks';
import { Title } from '../../../Atoms';
import SCREENS from '../../../../helpers/screens';

import './Houses.css';

const houses = b.with('houses');

const Houses = () => {
  const { title, list } = useHouses();

  return (
    <div className={houses()}>
      <Title text={title} />
      <div className={houses('list')}>
        {list.map(item => (
          <a
            key={item._id}
            className={houses('item')}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            title={item.name}
            id={`${SCREENS.CHARTS__SCREEN}-${item.name}`}
          >
            <img src={`/static/images/houses/${item.image}`} alt={item.name} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Houses;
