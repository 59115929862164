import { createSelector } from 'reselect';

import SCREENS from '../../helpers/screens';
import CHARTS from '../../helpers/charts';
import HOUSE_TYPE from '../../helpers/houseTypes';

export const selectActiveScreen = state => state.screen;
export const selectSettings = (state, screen) => state.settings[screen];
export const selectSelectedOptions = state => state.selectedOptions;
export const selectConfiguration = state => state.configuration;
export const selectConfigurationId = state => selectConfiguration(state).id;
export const selectConfigurationEmail = state => selectConfiguration(state).email;
export const selectSelectedOption = (state, screen) => selectSelectedOptions(state)[screen];
const selectCoefficients = (state, type) => state.coefficients[type];
const selectCountries = state => state.countries;

export const selectHouses = state => state.houses;

export const selectChartTechnology = state => state.chart.technology;
export const selectChartFast = state => state.chart.fast;

export const selectChartsSettings = state => selectSettings(state, SCREENS.CHARTS__SCREEN);
export const selectPurposeSettings = state => selectSettings(state, SCREENS.PURPOSE__SCREEN);
export const selectHouseTypeSettings = state => selectSettings(state, SCREENS.HOUSE_TYPE__SCREEN);
export const selectCountrySettings = state => selectSettings(state, SCREENS.COUNTRY__SCREEN);

export const selectSelectedFootprint = state => selectSelectedOption(state, SCREENS.FOOTPRINT__SCREEN);
export const selectSelectedNumberOfFloors = state => selectSelectedOption(state, SCREENS.NUMBER_OF_FLOORS__SCREEN);
export const selectSelectedNumberOfUnits = state => selectSelectedOption(state, SCREENS.NUMBER_OF_UNITS__SCREEN);
export const selectSelectedNumberOfBuildings = state =>
  selectSelectedOption(state, SCREENS.NUMBER_OF_BUILDINGS__SCREEN);
export const selectSelectedUnitSize = state => selectSelectedOption(state, SCREENS.UNIT_SIZE__SCREEN);
export const selectSelectedHouseType = state => selectSelectedOption(state, SCREENS.HOUSE_TYPE__SCREEN);
export const selectSelectedPurpose = state => selectSelectedOption(state, SCREENS.PURPOSE__SCREEN);
export const selectSelectedCountry = state => selectSelectedOption(state, SCREENS.COUNTRY__SCREEN);

const selectTimeCoefficients = state => selectCoefficients(state, CHARTS.TIME);

export const getIsPairHouseSelected = createSelector(selectSelectedHouseType, type => type === HOUSE_TYPE.PAIR);
export const getIsRowHouseSelected = createSelector(selectSelectedHouseType, type => type === HOUSE_TYPE.ROW);
export const getIsApartmentSelected = createSelector(selectSelectedHouseType, type => type === HOUSE_TYPE.APARTMENT);

export const getSelectedTimeCoefficients = createSelector(
  selectTimeCoefficients,
  selectSelectedHouseType,
  (coefficients, houseType) => coefficients[houseType]
);

export const getIsConfigurationSent = createSelector(selectConfigurationEmail, id => Boolean(id));

export const getSelectedNumberOfUnitsInBuilding = createSelector(
  selectSelectedNumberOfUnits,
  getIsPairHouseSelected,
  (numberOfUnits, isPairType) => {
    if (isPairType) {
      return 2;
    }

    return 1;
  }
);

export const getNumberOfBuildings = createSelector(
  selectSelectedNumberOfBuildings,
  selectSelectedNumberOfUnits,
  getIsApartmentSelected,
  getSelectedNumberOfUnitsInBuilding,
  (numberOfBuildings, numberOfUnits, isApartmentType, numberOfUnitsInBuilding) => {
    if (isApartmentType) {
      return numberOfBuildings;
    }

    return Math.round(numberOfUnits / numberOfUnitsInBuilding);
  }
);

export const getSelectedFootprint = createSelector(
  getIsApartmentSelected,
  selectSelectedUnitSize,
  selectSelectedNumberOfFloors,
  selectSelectedFootprint,
  (isApartmentType, unitSize, numberOfFloors, footprint) => {
    if (isApartmentType) {
      return footprint;
    }

    return unitSize / numberOfFloors;
  }
);

export const getTotalSize = createSelector(
  getIsApartmentSelected,
  selectSelectedNumberOfFloors,
  selectSelectedNumberOfBuildings,
  selectSelectedFootprint,
  selectSelectedNumberOfUnits,
  selectSelectedUnitSize,
  (isApartmentType, numberOfFloors, numberOfBuildings, footprint, numberOfUnits, unitSize) => {
    if (isApartmentType) {
      return numberOfBuildings * numberOfFloors * footprint;
    }

    return numberOfUnits * unitSize;
  }
);

export const getLegend = createSelector(selectChartsSettings, ({ colors }) =>
  Object.keys(colors).map(name => ({ color: colors[name], name }))
);

export const getIsChartScreen = createSelector(selectActiveScreen, screen => screen === SCREENS.CHARTS__SCREEN);

export const getCountryList = createSelector(selectCountries, selectCountrySettings, (list, settings) => {
  const existed = settings.options.map(({ value }) => value.split('_')[0].toUpperCase());

  return list.filter(({ value }) => !existed.includes(value));
});
