import { all } from 'redux-saga/effects';

import watchCreateConfigurationSaga from './createConfigurationSaga';
import watchFetchCoefficientsSaga from './fetchCoefficientsSaga';
import watchFetchCountriesSaga from './fetchCountriesSaga';
import watchFetchHousesSaga from './fetchHousesSaga';
import watchSendConfigurationSaga from './sendConfigurationSaga';
import watchUpdateConfigurationSaga from './updateConfigurationSaga';
import watchShowChartsScreenSaga from './showChartsScreenSaga';

export default function* rootSaga() {
  yield all([
    watchCreateConfigurationSaga(),
    watchFetchCoefficientsSaga(),
    watchFetchCountriesSaga(),
    watchFetchHousesSaga(),
    watchSendConfigurationSaga(),
    watchUpdateConfigurationSaga(),
    watchShowChartsScreenSaga()
  ]);
}
