import { SEND_CONFIGURATION__SUCCESS, SEND_CONFIGURATION__FAILURE, CREATE_CONFIGURATION__SUCCESS } from '../constants';

export const initialState = {
  error: '',
  id: null,
  email: ''
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case CREATE_CONFIGURATION__SUCCESS: {
      return { ...state, id: payload.id };
    }
    case SEND_CONFIGURATION__SUCCESS:
      return { ...state, email: payload.email, error: '' };
    case SEND_CONFIGURATION__FAILURE:
      return { ...state, error: payload.error };
    default:
      return state;
  }
};
