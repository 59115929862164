import { call, takeLatest, select, delay } from 'redux-saga/effects';

import { selectConfigurationId, selectSelectedOptions } from '../redux/selectors';
import { SELECT_OPTION } from '../redux/constants';
import api from '../helpers/api';

export function* updateConfigurationSaga() {
  yield delay(2500);

  const selectedOptions = yield select(selectSelectedOptions);
  const id = yield select(selectConfigurationId);

  if (!id) {
    const { result } = yield call(api.createConfiguration);

    yield call(api.updateConfiguration, result, { selectedOptions });
  } else {
    yield call(api.updateConfiguration, id, { selectedOptions });
  }
}

export default function*() {
  yield takeLatest(SELECT_OPTION, updateConfigurationSaga);
}
