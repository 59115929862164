import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Description.css';

const description = b.with('description');

const Description = ({ text }) =>
  // eslint-disable-next-line react/no-danger
  text ? <p className={description()} dangerouslySetInnerHTML={{ __html: text }} /> : null;

Description.propTypes = {
  text: PropTypes.string
};

export default Description;
