import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import { Button, Title, Select, Explanation, Description } from '../../Atoms';
import SCREENS from '../../../helpers/screens';
import { useSetScreen, useSettings, useSelectedOptions } from '../../../helpers/hooks';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';
import { getIsApartmentSelected } from '../../../redux/selectors';

import CountryForm from './CountryForm';

const OTHER_COUNTRY_VALUE = 'other';

const countryScreen = b.with('country-screen');

const CountryScreen = () => {
  const [country, selectCountry] = useSelectedOptions(SCREENS.COUNTRY__SCREEN);
  const { additional, message, title, options, prevButton, nextButton, explanation } = useSettings(
    SCREENS.COUNTRY__SCREEN
  );
  const isApartmentSelected = useSelector(getIsApartmentSelected);
  const setPrevScreen = useSetScreen(isApartmentSelected ? SCREENS.FOOTPRINT__SCREEN : SCREENS.UNIT_SIZE__SCREEN);
  const setNextScreen = useSetScreen(SCREENS.LOADER__SCREEN);
  const isOtherCountry = country === OTHER_COUNTRY_VALUE;

  return (
    <Screen className={countryScreen()}>
      <ScreenContent>
        <Title text={title} />
        <Select options={options} onSelect={selectCountry} value={country} id="country-select" />
        {isOtherCountry ? (
          <CountryForm settings={additional} />
        ) : (
          <>
            <Description text={message} />
            {explanation ? <Explanation title={explanation.title} message={explanation.message} /> : null}
          </>
        )}
      </ScreenContent>
      <ScreenButtons>
        {isOtherCountry ? null : (
          <>
            <Button onClick={setPrevScreen} text={prevButton} outline id="prev-btn" />
            <Button onClick={setNextScreen} text={nextButton} disabled={!country} id="next-btn" />
          </>
        )}
      </ScreenButtons>
    </Screen>
  );
};

export default CountryScreen;
