import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen } from '../../../redux/selectors';

import './OptionList.css';

const optionList = b.with('option-list');

const OptionList = ({ options, value = '', onSelect }) => {
  const activeScreen = useSelector(selectActiveScreen);
  const handleChange = useCallback(
    e => {
      onSelect(e.currentTarget.dataset.value);
    },
    [onSelect]
  );

  return (
    <div className={optionList()}>
      {options.map(option => (
        <button
          className={optionList('option', { active: value === option.value })}
          key={option.value}
          data-value={option.value}
          type="button"
          onClick={handleChange}
          id={`${activeScreen}-option-${option.value}`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

OptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default OptionList;
