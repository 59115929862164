import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { useSettings } from '../../../../helpers/hooks';
import SCREENS from '../../../../helpers/screens';
import { Button, Popup } from '../../../Atoms';
import { getIsConfigurationSent } from '../../../../redux/selectors';
import ConfigurationForm from '../../../ConfigurationForm';

import './FormPopup.css';

const formPopup = b.with('form-popup');

const FormPopup = ({ onClose }) => {
  const timer = useRef(null);
  const isConfigurationSaved = useSelector(getIsConfigurationSent);
  const [formVisible, setFormVisible] = useState(false);
  const { popup: settings } = useSettings(SCREENS.LOADER__SCREEN);
  const showForm = useCallback(() => {
    setFormVisible(true);

    clearTimeout(timer.current);
  }, []);

  const handleClose = useCallback(
    timeout => {
      clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        onClose();
      }, timeout);
    },
    [onClose]
  );
  const hideForm = useCallback(() => {
    setFormVisible(false);

    handleClose(5000);
  }, [handleClose]);

  useEffect(() => {
    handleClose(15000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [handleClose, onClose]);

  useEffect(() => {
    if (isConfigurationSaved) {
      handleClose(1500);
    }
  }, [handleClose, isConfigurationSaved]);

  return (
    <Popup className={formPopup()}>
      {formVisible ? (
        <>
          <ConfigurationForm />
          {isConfigurationSaved ? null : (
            <button className={formPopup('back-button')} type="button" onClick={hideForm} id="loader-popup-back-btn">
              go back
            </button>
          )}
        </>
      ) : (
        <>
          <p className={formPopup('explanation')}>{settings.before}</p>
          <h1 className={formPopup('title')}>{settings.title}</h1>
          <p className={formPopup('explanation')}>{settings.after}</p>
          <div className={formPopup('buttons')}>
            <Button text={settings.cancelButton} onClick={onClose} outline small id="popup-cancel-btn" />
            <Button text={settings.saveButton} onClick={showForm} id="popup-save-btn" />
          </div>
        </>
      )}
    </Popup>
  );
};

FormPopup.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default FormPopup;
