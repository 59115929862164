import React, { useEffect, useState } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import { useSettings } from '../../../helpers/hooks';
import { Screen, ScreenContent } from '../Screen';
import { getIsConfigurationSent, getTimeChart } from '../../../redux/selectors';
import SCREENS from '../../../helpers/screens';
import { Title } from '../../Atoms';
import ConfigurationForm from '../../ConfigurationForm';
import EasLogo from '../../EASLogo';

import Chart from './Chart';
import Summary from './Summary';
import Legend from './Legend';
import Houses from './Houses';
import ChartSwitch from './ChartSwitch';

import './ChartsScreen.css';

const chartsScreen = b.with('charts-screen');

const ChartsScreen = () => {
  const [formVisible, setFormVisible] = useState(false);
  const isConfigurationSaved = useSelector(getIsConfigurationSent);
  const settings = useSettings(SCREENS.CHARTS__SCREEN);
  const { labels, datasets } = useSelector(getTimeChart);

  useEffect(() => {
    if (!isConfigurationSaved) {
      setFormVisible(true);
    }
  }, [isConfigurationSaved]);

  return (
    <Screen className={chartsScreen()}>
      <div className={chartsScreen('eas')}>
        <EasLogo />
      </div>
      <ScreenContent className={chartsScreen('content')}>
        <Title text={settings.title} />
        <div className={chartsScreen('main')}>
          <div className={chartsScreen('chart')}>
            <Chart labels={labels} datasets={datasets} />
          </div>
          <div className={chartsScreen('legend')}>
            <Legend />
          </div>
        </div>
        <ChartSwitch />
        <Houses />
        <Summary />
        {formVisible ? (
          <div className={chartsScreen('form')}>
            <ConfigurationForm />
          </div>
        ) : null}
      </ScreenContent>
    </Screen>
  );
};

export default ChartsScreen;
