import { put, takeLatest, delay } from 'redux-saga/effects';

import SCREENS from '../helpers/screens';
import { setScreen } from '../redux/actions';
import { SHOW_CHARTS } from '../redux/constants';

export function* showChartsScreenSaga({ payload }) {
  if (payload.delay !== -1) {
    yield delay(payload.delay);

    yield put(setScreen(SCREENS.CHARTS__SCREEN));
  }
}

export default function*() {
  yield takeLatest(SHOW_CHARTS, showChartsScreenSaga);
}
