import React from 'react';
import b from 'b_';

import { useSummary } from '../../../../helpers/hooks';
import { Title } from '../../../Atoms';

import './Summary.css';

const summary = b.with('summary');

const Summary = () => {
  const { title, options } = useSummary();

  return (
    <div className={summary()}>
      <Title text={title} />
      {options.map(item => (
        <div className={summary('item')} key={item.label}>
          <div className={summary('item-header')}>
            <span className={summary('item-label')}>{item.label}</span>
            {/* eslint-disable-next-line react/no-danger */}
            <span className={summary('item-value')} dangerouslySetInnerHTML={{ __html: item.value }} />
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={summary('item-description')} dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      ))}
    </div>
  );
};

export default Summary;
