import React, { useEffect, useState, useCallback } from 'react';
import b from 'b_';
import { CSSTransition } from 'react-transition-group';

import { useShowCharts } from '../../../helpers/hooks';
import { Screen, ScreenContent } from '../Screen';

import FormPopup from './FormPopup';
import Loader from './Loader';

import './LoaderScreen.css';

const loaderScreen = b.with('loader-screen');

const LoaderScreen = () => {
  const showCharts = useShowCharts();
  const [popupVisible, setPopupVisible] = useState(false);
  const [ready, setReady] = useState(false);
  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);

    setTimeout(() => {
      setReady(true);
    }, 500);
  }, [setPopupVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupVisible(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (ready) {
      showCharts(1500);
    }
  }, [ready, showCharts]);

  return (
    <Screen className={loaderScreen()}>
      <ScreenContent className="screen__content">
        <Loader text={ready ? 'Ready' : 'Calculating...'} ready={ready} />
        <CSSTransition in={popupVisible} timeout={450} classNames={loaderScreen('popup')} unmountOnExit>
          <FormPopup onClose={handleClosePopup} />
        </CSSTransition>
      </ScreenContent>
    </Screen>
  );
};

export default LoaderScreen;
