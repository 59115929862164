import { combineReducers } from 'redux';

import chart from './chart';
import coefficients from './coefficients';
import configuration from './configuration';
import countries from './countries';
import houses from './houses';
import screen from './screen';
import selectedOptions from './selectedOptions';

const reducer = combineReducers({
  chart,
  coefficients,
  configuration,
  countries,
  houses,
  screen,
  settings: (state = {}) => state,
  selectedOptions
});

export default reducer;
