/* eslint-disable react/no-danger */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen } from '../../../redux/selectors';

import './Explanation.css';

const explanation = b.with('explanation');

const Explanation = ({ title, message }) => {
  const messageRef = useRef(null);
  const [open, setOpen] = useState(false);
  const activeScreen = useSelector(selectActiveScreen);
  const id = `${activeScreen}-explanation-header`;

  return (
    <div className={explanation()}>
      <button className={explanation('header', { open })} type="button" onClick={() => setOpen(!open)} id={id}>
        <span className={explanation('title')}>{title}</span>
      </button>
      <div
        ref={messageRef}
        className={explanation('message', { open })}
        dangerouslySetInnerHTML={{ __html: message }}
        style={{ height: open ? `${messageRef.current.scrollHeight}px` : '0px' }}
      />
    </div>
  );
};

Explanation.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default Explanation;
