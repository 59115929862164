import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Select.css';

const select = b.with('select');

const Select = ({ options, value = '', placeholder = 'Select...', modern = false, onSelect, id }) => {
  const handleChange = useCallback(
    e => {
      onSelect(e.target.value);
    },
    [onSelect]
  );

  return (
    <div className={select('wrapper')}>
      <select value={value} onChange={handleChange} className={select({ selected: Boolean(value), modern })} id={id}>
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <i className={select('icon')} />
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  modern: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string
};

export default Select;
