import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchCountriesSuccess } from '../redux/actions';
import { FETCH_COUNTRIES } from '../redux/constants';
import api from '../helpers/api';

export function* fetchCountriesSaga() {
  const { result } = yield call(api.fetchCountries);

  if (result) {
    yield put(fetchCountriesSuccess(result));
  }
}

export default function*() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesSaga);
}
