import { SET_CHART_TYPE, SET_CHART_TECHNOLOGY, TOGGLE_CHART_FAST } from '../constants';
import CHARTS from '../../helpers/charts';
import TECHNOLOGIES from '../../helpers/tecnologies';

export const initialState = {
  type: CHARTS.TIME,
  technology: TECHNOLOGIES.ALL,
  fast: false
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_CHART_TYPE:
      return { ...state, type: payload.type };
    case SET_CHART_TECHNOLOGY:
      return { ...state, technology: payload.technology };
    case TOGGLE_CHART_FAST:
      return { ...state, fast: !state.fast };
    default:
      return state;
  }
};
