import React from 'react';

import { useSelectedOptions, useSettings } from '../../../helpers/hooks';
import SCREENS from '../../../helpers/screens';
import { Slider } from '../../Atoms';
import TotalSize from '../TotalSize';

const SCREEN = SCREENS.FOOTPRINT__SCREEN;

const FootprintControl = () => {
  const { options, totalMessage } = useSettings(SCREEN);
  const [footprint, setFootprint] = useSelectedOptions(SCREEN);
  const [houseType] = useSelectedOptions(SCREENS.HOUSE_TYPE__SCREEN);

  return (
    <>
      <Slider
        min={options.min}
        max={options.max}
        def={options.def}
        value={footprint}
        onChange={setFootprint}
        unit="m<sup>2</sup>"
        range={false}
        idPrefix={SCREEN}
      />
      <TotalSize message={totalMessage[houseType] || totalMessage.def} />
    </>
  );
};

export default FootprintControl;
