import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './SidebarButton.css';

const sidebarButton = b.with('sidebar-button');

const SidebarButton = ({ open = false, onClick = () => {} }) => (
  <button className={sidebarButton({ open })} type="button" onClick={onClick}>
    <span className={sidebarButton('icon', { open })}>
      <span className={open ? 'open' : ''} />
      <span className={open ? 'open' : ''} />
      <span className={open ? 'open' : ''} />
    </span>
    <span className={sidebarButton('title')}>{open ? 'Close' : 'Customise'}</span>
  </button>
);

SidebarButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func
};

export default SidebarButton;
