import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import b from 'b_';

import { Button, Checkbox, Input } from '../Atoms';
import { useSettings } from '../../helpers/hooks';
import SCREENS from '../../helpers/screens';
import { sendConfiguration } from '../../redux/actions';
import { getIsConfigurationSent, selectActiveScreen } from '../../redux/selectors';

import SuccessMessage from './SuccessMessage';

import './ConfigurationForm.css';

const configurationForm = b.with('configuration-form');

const ConfigurationForm = () => {
  const dispatch = useDispatch();
  const isConfigurationSaved = useSelector(getIsConfigurationSent);
  const activeScreen = useSelector(selectActiveScreen);
  const { form: settings } = useSettings(SCREENS.LOADER__SCREEN);
  const description = settings.description[activeScreen] || settings.description.def;
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      dispatch(sendConfiguration(email, consent, settings.consent));
    },
    [dispatch, email, consent, settings.consent]
  );

  if (isConfigurationSaved) {
    return <SuccessMessage settings={settings.saved[activeScreen] || settings.saved.def} />;
  }

  return (
    <form onSubmit={handleSubmit} className={configurationForm()}>
      <p className={configurationForm('description', { type: activeScreen })}>{description}</p>
      <Checkbox
        onChange={setConsent}
        checked={consent}
        label={settings.consent}
        name={`${activeScreen}-configuration-form-consent`}
      />
      <div className={configurationForm('controls')}>
        <Input
          onChange={setEmail}
          value={email}
          placeholder={settings.inputPlaceholder}
          type="email"
          required
          id={`${activeScreen}-configuration-form-email`}
        />
        <div className={configurationForm('submit')}>
          <Button
            text={settings.sendButton}
            type="submit"
            disabled={!consent || !email}
            id="configuration-form-send-btn"
          />
        </div>
      </div>
    </form>
  );
};

export default ConfigurationForm;
