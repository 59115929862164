export const SET_SCREEN = 'SET_SCREEN';
export const SELECT_OPTION = 'SELECT_OPTION';

export const SET_CHART_TYPE = 'SET_CHART_TYPE';
export const SET_CHART_TECHNOLOGY = 'SET_CHART_TECHNOLOGY';
export const TOGGLE_CHART_FAST = 'TOGGLE_CHART_FAST';

export const FETCH_COEFFICIENTS = 'FETCH_COEFFICIENTS';
export const FETCH_COEFFICIENTS__SUCCESS = 'FETCH_COEFFICIENTS__SUCCESS';

export const CREATE_CONFIGURATION = 'CREATE_CONFIGURATION';
export const CREATE_CONFIGURATION__SUCCESS = 'CREATE_CONFIGURATION__SUCCESS';

export const SEND_CONFIGURATION = 'SEND_CONFIGURATION';
export const SEND_CONFIGURATION__SUCCESS = 'SEND_CONFIGURATION__SUCCESS';
export const SEND_CONFIGURATION__FAILURE = 'SEND_CONFIGURATION__FAILURE';

export const SHOW_CHARTS = 'SHOW_CHARTS';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES__SUCCESS = 'FETCH_COUNTRIES__SUCCESS';

export const FETCH_HOUSES = 'FETCH_HOUSES';
export const FETCH_HOUSES__SUCCESS = 'FETCH_HOUSES__SUCCESS';
