import React from 'react';

import Button from '../../Atoms/Button';
import SCREENS from '../../../helpers/screens';
import { useSetScreen, useSettings } from '../../../helpers/hooks';
import Title from '../../Atoms/Title';
import Description from '../../Atoms/Description';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';

import Technologies from './Technologies';

const ExplanationScreen = () => {
  const { title, message, button, icons } = useSettings(SCREENS.EXPLANATION__SCREEN);
  const setNextScreen = useSetScreen(SCREENS.PURPOSE__SCREEN);

  return (
    <Screen>
      <ScreenContent>
        <Title text={title} />
        <Description text={message} />
        <Technologies icons={icons} />
      </ScreenContent>
      <ScreenButtons>
        <Button onClick={setNextScreen} text={button} id="next-btn" />
      </ScreenButtons>
    </Screen>
  );
};

export default ExplanationScreen;
