import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Technologies.css';

const technologies = b.with('technologies');

const Technologies = ({ icons = {} }) => (
  <div className={technologies()}>
    {Object.keys(icons).map(type => (
      <div className={technologies('technology')} key={type}>
        <i className={technologies('technology-icon', { type })} />
        <span className={technologies('technology-name')}>{icons[type]}</span>
      </div>
    ))}
  </div>
);

Technologies.propTypes = {
  icons: PropTypes.shape({})
};

export default Technologies;
