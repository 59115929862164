import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import SCREENS from '../../../helpers/screens';
import { selectActiveScreen } from '../../../redux/selectors';

import './Progress.css';

const progress = b.with('progress');

const MAP = {
  [SCREENS.PURPOSE__SCREEN]: 1,
  [SCREENS.HOUSE_TYPE__SCREEN]: 2,
  [SCREENS.NUMBER_OF_UNITS__SCREEN]: 3,
  [SCREENS.NUMBER_OF_BUILDINGS__SCREEN]: 3,
  [SCREENS.NUMBER_OF_FLOORS__SCREEN]: 4,
  [SCREENS.FOOTPRINT__SCREEN]: 5,
  [SCREENS.UNIT_SIZE__SCREEN]: 5,
  [SCREENS.COUNTRY__SCREEN]: 6
};

const TOTAL_STEPS = Math.max(...Object.values(MAP));

const STEPS = Array.from(Array(TOTAL_STEPS), (_, i) => i + 1);

const Progress = () => {
  const activeScreen = useSelector(selectActiveScreen);

  if (!MAP[activeScreen]) return null;

  const activeStepNumber = MAP[activeScreen];

  return (
    <div className={progress()}>
      {STEPS.map(step => (
        <div
          className={progress('step', { completed: step <= activeStepNumber })}
          key={step}
          style={{ width: `calc((100% - (${TOTAL_STEPS - 1} * 4px)) / ${TOTAL_STEPS})` }}
        />
      ))}
    </div>
  );
};

export default Progress;
