import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import Helmet from './Helmet';
import Screens from './components/Screens';

import './style.css';

const App = () => (
  <>
    <Helmet />
    <Switch>
      <Route path="/" exact component={Screens} />
      <Route path="/:id" exact component={Screens} />
    </Switch>
  </>
);

export default hot(App);
