import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import Progress from '../Progress';

import './Screen.css';

const screen = b.with('screen');

export const Screen = ({ children, className = '' }) => (
  <div className={screen()}>
    <div className={`${screen('container')} ${className}`}>
      <div className={screen('progress')}>
        <Progress />
      </div>
      {children}
    </div>
  </div>
);

Screen.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export const ScreenContent = ({ children, className = '' }) => (
  <div className={`${screen('content')} ${className}`}>{children}</div>
);

ScreenContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export const ScreenButtons = ({ children, className = '' }) => (
  <div className={`${screen('buttons')} ${className}`}>{children}</div>
);

ScreenButtons.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
