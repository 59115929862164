import React from 'react';
import { Helmet as RHelmet } from 'react-helmet-async';

import { useSettings } from './helpers/hooks';
import favicon from './assets/images/apple-icon-152x152.png';

const Helmet = () => {
  const { title, description, keywords, themeColor, ogTitle, ogDescription, ogImage, url } = useSettings('helmet');

  return (
    <RHelmet>
      <html lang="en" />
      <title>{title}</title>

      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="theme-color" content={themeColor} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={url} />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,600|Roboto:300,400,700&display=swap"
        rel="stylesheet"
      />

      <link rel="icon" type="image/png" sizes="152x152" href={favicon} />
    </RHelmet>
  );
};

export default Helmet;
