import { call, put, select, takeLatest } from 'redux-saga/effects';

import { createConfigurationSuccess } from '../redux/actions';
import { CREATE_CONFIGURATION } from '../redux/constants';
import api from '../helpers/api';
import { selectConfigurationId } from '../redux/selectors';

export function* createConfigurationSaga() {
  const id = yield select(selectConfigurationId);

  if (id) {
    return;
  }

  const { result } = yield call(api.createConfiguration);

  if (result) {
    yield put(createConfigurationSuccess(result));
  }
}

export default function*() {
  yield takeLatest(CREATE_CONFIGURATION, createConfigurationSaga);
}
