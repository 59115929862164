import { createSelector } from 'reselect';

import SCREENS from '../../helpers/screens';

import {
  getIsApartmentSelected,
  getSelectedFootprint,
  selectChartsSettings,
  selectCountrySettings,
  selectHouseTypeSettings,
  selectPurposeSettings,
  selectSelectedCountry,
  selectSelectedHouseType,
  selectSelectedNumberOfFloors,
  selectSelectedNumberOfBuildings,
  selectSelectedNumberOfUnits,
  selectSelectedPurpose,
  selectSelectedUnitSize
} from './common';

export function parseArray(arr) {
  return arr.reduce((acc, item) => {
    acc[item.value] = item.label;

    return acc;
  }, {});
}

export function getMinLimitFromArray(arr, number) {
  let index;

  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] > number) break;

    index = i;
  }

  return arr[index];
}

const getDictionaries = createSelector(
  selectPurposeSettings,
  selectHouseTypeSettings,
  selectCountrySettings,
  (purposeSettings, houseTypeSettings, countrySettings) => ({
    [SCREENS.PURPOSE__SCREEN]: parseArray(purposeSettings.options),
    [SCREENS.HOUSE_TYPE__SCREEN]: parseArray(houseTypeSettings.options),
    [SCREENS.COUNTRY__SCREEN]: parseArray(countrySettings.options)
  })
);

const getDescriptionByExactValue = (dictionary = {}, value) => {
  const common = dictionary.common ? `${dictionary.common}<br/>` : '';

  return `${common}${dictionary[value] || ''}`;
};

const getDescriptionByRangeValue = (dictionary = {}, value) => {
  const common = dictionary.common ? `${dictionary.common}<br/>` : '';
  const parseValues = Object.keys(dictionary).reduce((result, current) => {
    const parsed = Number(current);

    if (parsed) {
      result.push(parsed);
    }

    return result;
  }, []);

  const exactValue = getMinLimitFromArray(parseValues, value);

  return `${common}${dictionary[exactValue] || ''}`;
};

const map = {
  [SCREENS.PURPOSE__SCREEN]: getDescriptionByExactValue,
  [SCREENS.HOUSE_TYPE__SCREEN]: getDescriptionByExactValue,
  [SCREENS.COUNTRY__SCREEN]: getDescriptionByExactValue,
  [SCREENS.NUMBER_OF_BUILDINGS__SCREEN]: getDescriptionByRangeValue,
  [SCREENS.NUMBER_OF_UNITS__SCREEN]: getDescriptionByRangeValue,
  [SCREENS.NUMBER_OF_FLOORS__SCREEN]: getDescriptionByRangeValue,
  [SCREENS.FOOTPRINT__SCREEN]: getDescriptionByRangeValue,
  [SCREENS.UNIT_SIZE__SCREEN]: getDescriptionByRangeValue
};

const getSummaryItem = (summarySettings, key, value, dictionary) => ({
  label: summarySettings.labels[key],
  value: dictionary ? dictionary[value] : value,
  description: map[key](summarySettings.descriptions[key], value)
});

const getPurposeSummary = createSelector(
  getDictionaries,
  selectSelectedPurpose,
  selectChartsSettings,
  (dictionaries, value, chartSettings) => {
    const { summary } = chartSettings;
    const dictionary = dictionaries[SCREENS.PURPOSE__SCREEN];

    return getSummaryItem(summary, SCREENS.PURPOSE__SCREEN, value, dictionary);
  }
);

const getHouseTypeSummary = createSelector(
  getDictionaries,
  selectSelectedHouseType,
  selectChartsSettings,
  (dictionaries, value, chartSettings) => {
    const { summary } = chartSettings;
    const dictionary = dictionaries[SCREENS.HOUSE_TYPE__SCREEN];

    return getSummaryItem(summary, SCREENS.HOUSE_TYPE__SCREEN, value, dictionary);
  }
);

const getNumberOfUnitsSummary = createSelector(
  selectSelectedNumberOfUnits,
  selectChartsSettings,
  (value, chartSettings) => {
    const { summary } = chartSettings;

    return getSummaryItem(summary, SCREENS.NUMBER_OF_UNITS__SCREEN, value);
  }
);

const getNumberOfBuildingsSummary = createSelector(
  selectSelectedNumberOfBuildings,
  selectChartsSettings,
  (value, chartSettings) => {
    const { summary } = chartSettings;

    return getSummaryItem(summary, SCREENS.NUMBER_OF_BUILDINGS__SCREEN, value);
  }
);

const getNumberOfFloorsSummary = createSelector(
  selectSelectedNumberOfFloors,
  selectChartsSettings,
  (value, chartSettings) => {
    const { summary } = chartSettings;

    return getSummaryItem(summary, SCREENS.NUMBER_OF_FLOORS__SCREEN, value);
  }
);

const getUnitSizeSummary = createSelector(selectSelectedUnitSize, selectChartsSettings, (value, chartSettings) => {
  const { summary } = chartSettings;

  return getSummaryItem(summary, SCREENS.UNIT_SIZE__SCREEN, value);
});

const getFootprintSummary = createSelector(getSelectedFootprint, selectChartsSettings, (value, chartSettings) => {
  const { summary } = chartSettings;
  const result = getSummaryItem(summary, SCREENS.FOOTPRINT__SCREEN, value);

  result.value = `${value}m<sup>2</sup>`;

  return result;
});

const getCountrySummary = createSelector(
  getDictionaries,
  selectSelectedCountry,
  selectChartsSettings,
  (dictionaries, value, chartSettings) => {
    const { summary } = chartSettings;
    const dictionary = dictionaries[SCREENS.COUNTRY__SCREEN];

    return getSummaryItem(summary, SCREENS.COUNTRY__SCREEN, value, dictionary);
  }
);

export const getSummary = createSelector(
  selectChartsSettings,
  getPurposeSummary,
  getHouseTypeSummary,
  getNumberOfUnitsSummary,
  getNumberOfFloorsSummary,
  getUnitSizeSummary,
  getFootprintSummary,
  getNumberOfBuildingsSummary,
  getCountrySummary,
  getIsApartmentSelected,
  (
    chartSettings,
    purpose,
    houseType,
    numberOfUnits,
    numberOfFloors,
    unitSize,
    footprint,
    numberOfBuildings,
    country,
    isApartmentType
  ) => {
    const options = [
      purpose,
      houseType,
      isApartmentType ? numberOfBuildings : numberOfUnits,
      numberOfFloors,
      isApartmentType ? footprint : unitSize,
      country
    ];

    return {
      title: chartSettings.summary.title,
      options
    };
  }
);
