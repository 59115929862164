import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectSettings, selectSelectedOption, getCountryList, selectActiveScreen } from '../redux/selectors';
import { setScreen, selectOption, fetchCoefficients, showCharts, fetchCountries, fetchHouses } from '../redux/actions';
import { getSummary } from '../redux/selectors/summary';
import { getAvailableHouses } from '../redux/selectors/houses';

import SCREENS from './screens';

export function useSettings(screen) {
  return useSelector(state => selectSettings(state, screen)) || {};
}

export function useSelectedOptions(screen) {
  const dispatch = useDispatch();

  const selectValue = useCallback(
    value => {
      dispatch(selectOption(screen, value));
    },
    [dispatch, screen]
  );

  const selectedValue = useSelector(state => selectSelectedOption(state, screen));

  return [selectedValue, selectValue];
}

export function useSetScreen(screen) {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setScreen(screen));
  }, [dispatch, screen]);
}

export function useFetchCoefficients() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(fetchCoefficients());
  }, [dispatch]);
}

export function useShowCharts() {
  const dispatch = useDispatch();

  return useCallback(
    timeout => {
      dispatch(showCharts(timeout));
    },
    [dispatch]
  );
}

const PRIVATE_PURPOSE = 'private';

export function useIsB2C() {
  const [purpose] = useSelectedOptions(SCREENS.PURPOSE__SCREEN);

  return purpose === PRIVATE_PURPOSE;
}

export function useSummary() {
  return useSelector(getSummary);
}

export function useCountries() {
  const dispatch = useDispatch();
  const list = useSelector(getCountryList);

  useEffect(() => {
    if (!list.length) {
      dispatch(fetchCountries());
    }
  });

  return list;
}

export function useHouses() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHouses());
  }, [dispatch]);

  return useSelector(getAvailableHouses);
}

export function useIsEASLogoVisible() {
  const active = useSelector(selectActiveScreen);

  return active === SCREENS.WELCOME__SCREEN || active === SCREENS.CHARTS__SCREEN;
}
