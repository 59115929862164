import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import { getLegend } from '../../../../redux/selectors';

import './Legend.css';

const legendStyle = b.with('legend');

const Legend = () => {
  const legend = useSelector(getLegend);

  return (
    <div className={legendStyle()}>
      {legend.map(({ name, color }) => (
        <div key={name} className={legendStyle('item')}>
          <span className={legendStyle('name')} style={{ color }}>
            {name}
          </span>
          <span className={legendStyle('color')} style={{ background: color }} />
        </div>
      ))}
    </div>
  );
};

export default Legend;
