import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen } from '../../../redux/selectors';

import HouseIcon from './HouseIcon';

import './HouseTypeOptionList.css';

const houseTypeOptionList = b.with('house-type-option-list');

const HouseTypeOptionList = ({ options, value = '', onSelect }) => {
  const activeScreen = useSelector(selectActiveScreen);
  const handleChange = useCallback(
    e => {
      onSelect(e.currentTarget.dataset.value);
    },
    [onSelect]
  );

  return (
    <div className={houseTypeOptionList()}>
      {options.map(option => (
        <button
          className={houseTypeOptionList('option', { active: value === option.value })}
          key={option.value}
          data-value={option.value}
          type="button"
          id={`${activeScreen}-option-${option.value}`}
          onClick={handleChange}
        >
          <div className={houseTypeOptionList('icon')}>
            <HouseIcon type={option.value} active={value === option.value} />
          </div>
          <span className={houseTypeOptionList('label')}>{option.label}</span>
        </button>
      ))}
    </div>
  );
};

HouseTypeOptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default HouseTypeOptionList;
