import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchCoefficientsSuccess } from '../redux/actions';
import { FETCH_COEFFICIENTS } from '../redux/constants';
import api from '../helpers/api';

export function* fetchCoefficientsSaga() {
  const { result } = yield call(api.fetchCoefficients);

  if (result) {
    yield put(fetchCoefficientsSuccess(result));
  }
}

export default function*() {
  yield takeLatest(FETCH_COEFFICIENTS, fetchCoefficientsSaga);
}
