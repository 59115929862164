import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';

import './SuccessMessage.css';

const successMessage = b.with('success-message');

const SuccessMessage = ({ settings }) => (
  <div className={successMessage()}>
    <h1 className={successMessage('title')}>{settings.title}</h1>
    <p className={successMessage('text')}>{settings.text}</p>
  </div>
);

SuccessMessage.propTypes = {
  settings: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default SuccessMessage;
