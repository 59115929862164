import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import Button from '../Button';

import './Popup.css';

const popup = b.with('popup');

const Popup = ({ onClose, children, className = '', outline = false, button }) => (
  <div className={popup()}>
    <div className={`${popup('container')} ${className}`}>
      <div className={popup('content')}>{children}</div>
      <div className={popup('buttons')}>
        {onClose ? <Button text="Close" onClick={onClose} outline={outline} /> : null}
        {button || null}
      </div>
    </div>
  </div>
);

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.node,
  outline: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string
};

export default Popup;
