import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './HouseIcon.css';

const ACTIVE_COLOR = '#27AE60';
const INACTIVE_COLOR = '#828282';

const houseIcon = b.with('house-icon');

const getApartmentIcon = color => (
  <svg width="100%" height="100%" viewBox="0 0 151 147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="96" width="139" height="51" fill={color} />
    <rect x="83" width="56" height="147" fill={color} />
    <rect x="6" y="8" width="72" height="83" fill={color} />
    <rect x="107" y="11" width="43" height="27" fill="white" stroke={color} strokeWidth="2" />
    <rect x="20" y="36" width="43" height="27" fill="white" stroke={color} strokeWidth="2" />
    <rect x="84" y="118" width="16" height="29" fill="white" />
    <rect x="6" y="118" width="71" height="12" fill="white" />
    <rect x="107" y="59" width="43" height="27" fill="white" stroke={color} strokeWidth="2" />
    <rect x="107" y="108" width="43" height="27" fill="white" stroke={color} strokeWidth="2" />
  </svg>
);

const getSingleIcon = color => (
  <svg width="100%" height="100%" viewBox="0 0 79 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="32" width="63" height="63" fill={color} />
    <path d="M39.5 0L78.0381 33H0.961868L39.5 0Z" fill={color} />
    <rect x="33" y="76" width="13" height="19" fill="white" />
    <rect x="14" y="51" width="19" height="12" fill="white" />
  </svg>
);

const getPairIcon = color => (
  <svg width="100%" height="100%" viewBox="0 0 133 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="14" y="40" width="105" height="75" fill={color} />
    <path d="M66.5 3L124.091 42H8.90931L66.5 3Z" fill={color} />
    <rect x="39" y="93" width="15" height="22" fill="white" />
    <rect x="78" y="93" width="15" height="22" fill="white" />
    <rect x="39" y="63" width="15" height="15" fill="white" />
    <rect x="78" y="63" width="15" height="15" fill="white" />
    <rect x="65" width="3" height="115" fill="white" />
  </svg>
);

const getRowIcon = color => (
  <svg width="100%" height="100%" viewBox="0 0 177 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 15.6015L42 0V83H0V15.6015Z" fill={color} />
    <rect x="6" y="59" width="14" height="24" fill="white" />
    <rect x="26" y="59" width="10" height="17" fill="white" />
    <rect x="6" y="59" width="14" height="24" fill="white" />
    <rect x="6" y="28" width="30" height="19" fill="white" />
    <path d="M45 15.6015L87 0V83H45V15.6015Z" fill={color} />
    <rect x="51" y="59" width="14" height="24" fill="white" />
    <rect x="71" y="59" width="10" height="17" fill="white" />
    <rect x="51" y="59" width="14" height="24" fill="white" />
    <rect x="51" y="28" width="30" height="19" fill="white" />
    <path d="M90 15.6015L132 0V83H90V15.6015Z" fill={color} />
    <rect x="96" y="59" width="14" height="24" fill="white" />
    <rect x="116" y="59" width="10" height="17" fill="white" />
    <rect x="96" y="59" width="14" height="24" fill="white" />
    <rect x="96" y="28" width="30" height="19" fill="white" />
    <path d="M135 15.6015L177 0V83H135V15.6015Z" fill={color} />
    <rect x="141" y="59" width="14" height="24" fill="white" />
    <rect x="161" y="59" width="10" height="17" fill="white" />
    <rect x="141" y="59" width="14" height="24" fill="white" />
    <rect x="141" y="28" width="30" height="19" fill="white" />
  </svg>
);

const MAP = {
  single: getSingleIcon,
  pair: getPairIcon,
  row: getRowIcon,
  apartment: getApartmentIcon
};

const HouseIcon = ({ type = 'default', active }) => {
  const color = active ? ACTIVE_COLOR : INACTIVE_COLOR;
  const icon = MAP[type];

  return icon ? <div className={houseIcon()}>{icon(color)}</div> : null;
};

HouseIcon.propTypes = {
  type: PropTypes.string,
  active: PropTypes.bool
};

export default HouseIcon;
