import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Input.css';

const input = b.with('input');

const Input = ({ onChange, placeholder, value = '', type = 'text', required = false, modern = false, id }) => {
  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <input
      value={value}
      onChange={handleChange}
      className={input({ modern })}
      placeholder={placeholder}
      type={type}
      required={required}
      id={id}
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(['email', 'text']),
  required: PropTypes.bool,
  modern: PropTypes.bool
};

export default Input;
