import { createSelector } from 'reselect';

import TECHNOLOGIES from '../../helpers/tecnologies';
import STAGES from '../../helpers/stages';

import {
  getSelectedTimeCoefficients,
  getNumberOfBuildings,
  getSelectedFootprint,
  getTotalSize,
  selectSelectedNumberOfFloors,
  selectChartsSettings,
  selectChartTechnology,
  selectChartFast
} from './common';

const INDEXES = {
  onSite: 0,
  preCut: 1,
  elements: 2,
  modules: 3
};

const order = map => [map.onSite, map.preCut, map.elements, map.modules];

const getGroupCoefficients = ({ onSite, preCut, elements, modules }, stage) => ({
  onSite: onSite[stage],
  preCut: preCut[stage],
  elements: elements[stage],
  modules: modules[stage]
});

const getDesignAdjustments = createSelector(
  getSelectedTimeCoefficients,
  getSelectedFootprint,
  selectSelectedNumberOfFloors,
  (coefficients, footprint, numberOfFloors) => ({
    stage: STAGES.DESIGN_ADJUSTMENTS,
    data: order(getGroupCoefficients(coefficients, STAGES.DESIGN_ADJUSTMENTS)).map(coefficient =>
      Math.round(footprint * numberOfFloors * coefficient)
    )
  })
);

const getArchitecturalDesign = createSelector(
  getSelectedTimeCoefficients,
  getSelectedFootprint,
  selectSelectedNumberOfFloors,
  getDesignAdjustments,
  (coefficients, footprint, numberOfFloors, designAdjustments) => ({
    stage: STAGES.ARCHITECTURAL_DESIGN,
    data: order(getGroupCoefficients(coefficients, STAGES.ARCHITECTURAL_DESIGN)).map(
      (coefficient, i) => Math.round((footprint * numberOfFloors) ** 0.5 * coefficient) + designAdjustments.data[i]
    )
  })
);

const getContracting = createSelector(getSelectedTimeCoefficients, coefficients => ({
  stage: STAGES.CONTRACTING,
  data: order(getGroupCoefficients(coefficients, STAGES.CONTRACTING))
}));

const getProductionDesign = createSelector(
  getSelectedTimeCoefficients,
  getSelectedFootprint,
  selectSelectedNumberOfFloors,
  (coefficients, footprint, numberOfFloors) => ({
    stage: STAGES.PRODUCTION_DESIGN,
    data: order(getGroupCoefficients(coefficients, STAGES.PRODUCTION_DESIGN)).map(coefficient =>
      Math.round(footprint * coefficient * numberOfFloors)
    )
  })
);

const getManufacturing = createSelector(getSelectedTimeCoefficients, getTotalSize, (coefficients, totalSize) => ({
  stage: STAGES.MANUFACTURING,
  data: order(getGroupCoefficients(coefficients, STAGES.MANUFACTURING)).map(coefficient =>
    Math.round(totalSize * coefficient)
  )
}));

const getTransport = createSelector(getSelectedTimeCoefficients, getTotalSize, (coefficients, totalSize) => ({
  stage: STAGES.TRANSPORT,
  data: order(getGroupCoefficients(coefficients, STAGES.TRANSPORT)).map(coefficient =>
    Math.round(coefficient * (totalSize / 2))
  )
}));

const getAssembly = createSelector(
  getSelectedTimeCoefficients,
  selectSelectedNumberOfFloors,
  getTotalSize,
  (coefficients, numberOfFloors, totalSize) => ({
    stage: STAGES.ASSEMBLY,
    data: order(getGroupCoefficients(coefficients, STAGES.ASSEMBLY)).map(coefficient =>
      Math.round(coefficient * totalSize * ((numberOfFloors - 1) * 0.2 + 1))
    )
  })
);

const getExtFinish = createSelector(
  getSelectedTimeCoefficients,
  getNumberOfBuildings,
  selectSelectedNumberOfFloors,
  getSelectedFootprint,
  (coefficients, numberOfBuildings, numberOfFloors, footprint) => ({
    stage: STAGES.EXT_FINISH,
    data: order(getGroupCoefficients(coefficients, STAGES.EXT_FINISH)).map(
      coefficient => numberOfBuildings * Math.round(footprint ** 0.5 * 4 * numberOfFloors * 3 * coefficient)
    )
  })
);

const getTechInst = createSelector(getSelectedTimeCoefficients, getTotalSize, (coefficients, totalSize) => ({
  stage: STAGES.TECH_INST,
  data: order(getGroupCoefficients(coefficients, STAGES.TECH_INST)).map(coefficient =>
    Math.round(coefficient * (totalSize / 7))
  )
}));

const getIntFinish = createSelector(
  getSelectedTimeCoefficients,
  getNumberOfBuildings,
  selectSelectedNumberOfFloors,
  getSelectedFootprint,
  (coefficients, numberOfBuildings, numberOfFloors, footprint) => ({
    stage: STAGES.INT_FINIS,
    data: order(getGroupCoefficients(coefficients, STAGES.INT_FINIS)).map(
      coefficient => numberOfBuildings * Math.round(footprint ** 0.5 * 4 * numberOfFloors * 3 * coefficient)
    )
  })
);

const getChartData = createSelector(
  getArchitecturalDesign,
  getContracting,
  getProductionDesign,
  getDesignAdjustments,
  getManufacturing,
  getTransport,
  getAssembly,
  getExtFinish,
  getTechInst,
  getIntFinish,
  selectChartsSettings,
  selectChartFast,
  (
    architecturalDesign,
    contracting,
    productionDesign,
    designAdjustments,
    manufacturing,
    transport,
    assembly,
    extFinish,
    techInst,
    intFinis,
    chartSettings,
    fast
  ) => {
    const stages = [
      architecturalDesign,
      contracting,
      productionDesign,
      manufacturing,
      transport,
      assembly,
      extFinish,
      techInst,
      intFinis
    ];
    const { labels, colors } = chartSettings;

    if (fast) {
      stages[0] = { stage: stages[0].stage, data: [0, 0, 0, 0] };
      stages[2] = { stage: stages[2].stage, data: [0, 0, 0, 0] };
    }

    return {
      labels: order(labels),
      datasets: stages.map(({ data, stage }) => ({
        data,
        backgroundColor: colors[stage],
        label: stage
      }))
    };
  }
);

export default createSelector(getChartData, selectChartTechnology, (chart, technology) => {
  if (technology === TECHNOLOGIES.ALL) {
    return chart;
  }

  const index = INDEXES[technology];

  return {
    labels: [chart.labels[index]],
    datasets: chart.datasets.map(item => ({
      ...item,
      data: [item.data[index]]
    }))
  };
});
