import React, { useState } from 'react';

import { useIsEASLogoVisible } from '../helpers/hooks';

const EasLogo = () => {
  const [big, setBig] = useState(false);
  const isVisible = useIsEASLogoVisible();

  return isVisible ? (
    <div style={{ height: 'auto', width: `${big ? 350 : 230}px` }} className="eas">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src="/static/images/eas-logo.png"
        alt="European Regional Development Fund"
        title="European Regional Development Fund"
        width="100%"
        onClick={() => setBig(!big)}
      />
    </div>
  ) : null;
};

export default EasLogo;
