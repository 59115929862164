export default {
  ARCHITECTURAL_DESIGN: 'Architectural Design',
  CONTRACTING: 'Contracting',
  PRODUCTION_DESIGN: 'Production Design',
  DESIGN_ADJUSTMENTS: 'Design Adjustments',
  MANUFACTURING: 'Manufacturing',
  TRANSPORT: 'Transport',
  ASSEMBLY: 'Assembly',
  EXT_FINISH: 'Ext. Finish',
  TECH_INST: 'Tech. Inst.',
  INT_FINIS: 'Int. Finish'
};
