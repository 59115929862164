import { call, put, takeLatest, select } from 'redux-saga/effects';

import { selectConfigurationId, selectSelectedOptions } from '../redux/selectors';
import { sendConfigurationSuccess, sendConfigurationFailure } from '../redux/actions';
import { SEND_CONFIGURATION } from '../redux/constants';
import api from '../helpers/api';

export function* sendConfigurationSaga({ payload }) {
  const selectedOptions = yield select(selectSelectedOptions);
  const id = yield select(selectConfigurationId);
  const body = {
    selectedOptions,
    email: payload.email,
    consent: payload.consent,
    consentMessage: payload.consentMessage
  };
  const { result, error } = yield call(api.sendConfiguration, id, body);

  if (result) {
    yield put(sendConfigurationSuccess(result.email));
  } else {
    yield put(sendConfigurationFailure(error));
  }
}

export default function*() {
  yield takeLatest(SEND_CONFIGURATION, sendConfigurationSaga);
}
