import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen } from '../../../redux/selectors';

import './Button.css';

const button = b.with('button');

/* eslint-disable react/button-has-type,jsx-a11y/control-has-associated-label,react/no-danger */
const Button = ({
  text,
  onClick = () => {},
  outline = false,
  disabled = false,
  link = false,
  small = false,
  href = '',
  type = 'button',
  id
}) => {
  const activeScreen = useSelector(selectActiveScreen);
  const fullId = id ? `${activeScreen}-${id}` : undefined;

  if (link) {
    return (
      <a className={button({ outline, small })} href={href} target="_blank" rel="noopener noreferrer" id={fullId}>
        {text}
      </a>
    );
  }

  return (
    <button
      className={button({ outline, small, type })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={fullId}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  link: PropTypes.bool,
  href: PropTypes.string,
  id: PropTypes.string
};

export default Button;
