import {
  SET_SCREEN,
  SELECT_OPTION,
  FETCH_COEFFICIENTS,
  FETCH_COEFFICIENTS__SUCCESS,
  SEND_CONFIGURATION,
  SEND_CONFIGURATION__SUCCESS,
  SEND_CONFIGURATION__FAILURE,
  SHOW_CHARTS,
  SET_CHART_TYPE,
  SET_CHART_TECHNOLOGY,
  TOGGLE_CHART_FAST,
  FETCH_COUNTRIES__SUCCESS,
  FETCH_COUNTRIES,
  FETCH_HOUSES,
  FETCH_HOUSES__SUCCESS,
  CREATE_CONFIGURATION,
  CREATE_CONFIGURATION__SUCCESS
} from './constants';

export const setScreen = screen => ({
  type: SET_SCREEN,
  payload: { screen }
});

export const selectOption = (key, value) => ({
  type: SELECT_OPTION,
  payload: { key, value }
});

export const setChartType = type => ({
  type: SET_CHART_TYPE,
  payload: { type }
});

export const setChartTechnology = technology => ({
  type: SET_CHART_TECHNOLOGY,
  payload: { technology }
});

export const toggleChartFast = () => ({
  type: TOGGLE_CHART_FAST
});

export const fetchCoefficients = () => ({
  type: FETCH_COEFFICIENTS
});

export const fetchCoefficientsSuccess = coefficients => ({
  type: FETCH_COEFFICIENTS__SUCCESS,
  payload: { coefficients }
});

export const sendConfiguration = (email, consent, consentMessage) => ({
  type: SEND_CONFIGURATION,
  payload: { email, consent, consentMessage }
});

export const sendConfigurationSuccess = email => ({
  type: SEND_CONFIGURATION__SUCCESS,
  payload: { email }
});

export const sendConfigurationFailure = error => ({
  type: SEND_CONFIGURATION__FAILURE,
  payload: { error }
});

export const showCharts = delay => ({
  type: SHOW_CHARTS,
  payload: { delay }
});

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

export const fetchCountriesSuccess = countries => ({
  type: FETCH_COUNTRIES__SUCCESS,
  payload: { countries }
});

export const fetchHouses = () => ({
  type: FETCH_HOUSES
});

export const fetchHousesSuccess = houses => ({
  type: FETCH_HOUSES__SUCCESS,
  payload: { houses }
});

export const createConfiguration = () => ({
  type: CREATE_CONFIGURATION
});

export const createConfigurationSuccess = id => ({
  type: CREATE_CONFIGURATION__SUCCESS,
  payload: { id }
});
