import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Checkbox.css';

const checkbox = b.with('checkbox');

const Checkbox = ({ onChange, label, name, checked = false, title = '' }) => {
  const handleChange = useCallback(
    e => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <div className={checkbox()}>
      <input type="checkbox" onChange={handleChange} checked={checked} id={name} className={checkbox('input')} />
      <label className={checkbox('label', { checked, title: Boolean(title) })} htmlFor={name} title={title}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  title: PropTypes.string
};

export default Checkbox;
