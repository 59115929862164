import React from 'react';

import { useSelectedOptions, useSettings } from '../../../helpers/hooks';
import SCREENS from '../../../helpers/screens';
import { Slider } from '../../Atoms';

const SCREEN = SCREENS.NUMBER_OF_BUILDINGS__SCREEN;

const NumberOfBuildingsControl = () => {
  const { options } = useSettings(SCREEN);
  const [houseType] = useSelectedOptions(SCREENS.HOUSE_TYPE__SCREEN);
  const [number, selectNumberOfBuildings] = useSelectedOptions(SCREEN);
  const settings = options[houseType] || options.def;

  return (
    <Slider
      min={settings.min}
      max={settings.max}
      def={settings.def}
      value={number}
      onChange={selectNumberOfBuildings}
      idPrefix={SCREEN}
    />
  );
};

export default NumberOfBuildingsControl;
