import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Description, Explanation, Title } from '../../Atoms';
import SCREENS from '../../../helpers/screens';
import { useSetScreen, useSettings } from '../../../helpers/hooks';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';
import { getIsApartmentSelected } from '../../../redux/selectors';
import { NumberOfFloorsControl } from '../../Controls';

const NumberOfFloorsScreen = () => {
  const { title, message, prevButton, nextButton, explanation } = useSettings(SCREENS.NUMBER_OF_FLOORS__SCREEN);
  const isApartmentSelected = useSelector(getIsApartmentSelected);
  const setPrevScreen = useSetScreen(
    isApartmentSelected ? SCREENS.NUMBER_OF_BUILDINGS__SCREEN : SCREENS.NUMBER_OF_UNITS__SCREEN
  );
  const setNextScreen = useSetScreen(isApartmentSelected ? SCREENS.FOOTPRINT__SCREEN : SCREENS.UNIT_SIZE__SCREEN);

  return (
    <Screen>
      <ScreenContent>
        <Title text={title} />
        <NumberOfFloorsControl />
        <Description text={message} />
        {explanation ? <Explanation title={explanation.title} message={explanation.message} /> : null}
      </ScreenContent>
      <ScreenButtons>
        <Button onClick={setPrevScreen} text={prevButton} outline id="prev-btn" />
        <Button onClick={setNextScreen} text={nextButton} id="next-btn" />
      </ScreenButtons>
    </Screen>
  );
};

export default NumberOfFloorsScreen;
