import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { selectActiveScreen } from '../../redux/selectors';
import SCREENS from '../../helpers/screens';

import CountryScreen from './CountryScreen';
import HouseTypeScreen from './HouseTypeScreen';
import PurposeScreen from './PurposeScreen';
import NumberOfUnitsScreen from './NumberOfUnitsScreen';
import ExplanationScreen from './ExplanationScreen';
import NumberOfFloorsScreen from './NumberOfFloorsScreen';
import NumberOfBuildingsScreen from './NumberOfBuildingsScreen';
import FootprintScreen from './FootprintScreen';
import LoaderScreen from './LoaderScreen';
import WelcomeScreen from './WelcomeScreen';
import ChartsScreen from './ChartsScreen';
import UnitSizeScreen from './UnitSizeScreen';
import Sidebar from './Sidebar';

const screens = [
  { type: SCREENS.WELCOME__SCREEN, component: WelcomeScreen },
  { type: SCREENS.COUNTRY__SCREEN, component: CountryScreen },
  { type: SCREENS.HOUSE_TYPE__SCREEN, component: HouseTypeScreen },
  { type: SCREENS.PURPOSE__SCREEN, component: PurposeScreen },
  { type: SCREENS.NUMBER_OF_UNITS__SCREEN, component: NumberOfUnitsScreen },
  { type: SCREENS.NUMBER_OF_BUILDINGS__SCREEN, component: NumberOfBuildingsScreen },
  { type: SCREENS.EXPLANATION__SCREEN, component: ExplanationScreen },
  { type: SCREENS.NUMBER_OF_FLOORS__SCREEN, component: NumberOfFloorsScreen },
  { type: SCREENS.UNIT_SIZE__SCREEN, component: UnitSizeScreen },
  { type: SCREENS.FOOTPRINT__SCREEN, component: FootprintScreen },
  { type: SCREENS.LOADER__SCREEN, component: LoaderScreen },
  { type: SCREENS.CHARTS__SCREEN, component: ChartsScreen }
];

const Screens = () => {
  const activeScreen = useSelector(selectActiveScreen);

  return (
    <>
      <Sidebar />
      {screens.map(({ type, component: Component }) => (
        <CSSTransition key={type} in={type === activeScreen} timeout={450} classNames="screen" unmountOnExit>
          <Component />
        </CSSTransition>
      ))}
    </>
  );
};

export default Screens;
