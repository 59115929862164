import React, { useCallback } from 'react';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from '../../../Atoms';
import { selectChartFast } from '../../../../redux/selectors';
import { toggleChartFast } from '../../../../redux/actions';
import { useSettings } from '../../../../helpers/hooks';
import SCREENS from '../../../../helpers/screens';

import './ChartSwitch.css';

const chartSwitch = b.with('chart-switch');

const ChartSwitch = () => {
  const settings = useSettings(SCREENS.CHARTS__SCREEN);
  const dispatch = useDispatch();
  const fast = useSelector(selectChartFast);
  const toggle = useCallback(() => {
    dispatch(toggleChartFast());
  }, [dispatch]);

  return (
    <div className={chartSwitch()}>
      <div className={chartSwitch('control')}>
        <Switch label={settings.switch.label} onChange={toggle} name="chart-switch" checked={fast} />
      </div>
      <span className={chartSwitch('description')}>{settings.switch.description}</span>
    </div>
  );
};

export default ChartSwitch;
