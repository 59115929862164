import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import b from 'b_';

import { Button, Description } from '../../Atoms';
import { useSettings, useSetScreen } from '../../../helpers/hooks';
import SCREENS from '../../../helpers/screens';
import { Screen, ScreenContent } from '../Screen';
import EasLogo from '../../EASLogo';
import { createConfiguration } from '../../../redux/actions';

import './WelcomeScreen.css';

const welcomeScreen = b.with('welcome');

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  const { title, message, button } = useSettings(SCREENS.WELCOME__SCREEN);
  const setNextScreen = useSetScreen(SCREENS.EXPLANATION__SCREEN);

  useEffect(() => {
    dispatch(createConfiguration());
  }, [dispatch]);

  return (
    <Screen className={welcomeScreen()}>
      <ScreenContent>
        <div className={welcomeScreen('eas')}>
          <EasLogo />
        </div>
        <h1 className={welcomeScreen('title')}>{title}</h1>
        <Description text={message} />
        <div className={welcomeScreen('button')}>
          <Button onClick={setNextScreen} text={button} id="start" />
        </div>
      </ScreenContent>
    </Screen>
  );
};

export default WelcomeScreen;
