import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import api from '../../../../helpers/api';
import { useCountries } from '../../../../helpers/hooks';
import { Button, Checkbox, Description, Input, Popup, Select } from '../../../Atoms';

import './CountryForm.css';

const countryForm = b.with('country-form');

const CountryForm = ({ settings }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [, setResponse] = useState(false);
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const list = useCountries();
  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      api
        .saveCountryRequest({ code: country, email, consent, consentMessage: settings.checkbox })
        .then(({ result }) => {
          if (result) {
            setResponse(result);
            setPopupVisible(true);
          }
        });
    },
    [country, email, consent, settings.checkbox]
  );

  return (
    <form className={countryForm()} onSubmit={handleSubmit}>
      {popupVisible ? (
        <Popup onClose={handleClosePopup}>
          <Description text={settings.success} />
        </Popup>
      ) : null}
      <Description text={settings.message} />
      <div className={countryForm('control')}>
        <Select
          onSelect={setCountry}
          options={list}
          value={country}
          placeholder="Your country"
          modern
          id="country-form-list"
        />
      </div>
      <div className={countryForm('control')}>
        <Input
          onChange={setEmail}
          value={email}
          type="email"
          placeholder="Email"
          required
          modern
          id="country-form-email"
        />
      </div>
      <div className={countryForm('control')}>
        <Checkbox
          label={settings.checkbox}
          onChange={setConsent}
          checked={consent}
          name="country-form-consent"
          title={settings.tc}
        />
      </div>
      <div className={countryForm('control')}>
        <Button text={settings.button} type="submit" disabled={!email || !country || !consent} id="form-notify" />
      </div>
    </form>
  );
};

CountryForm.propTypes = {
  settings: PropTypes.shape({
    button: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    checkbox: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
    tc: PropTypes.string
  })
};

export default CountryForm;
