const CHART_OPTIONS = {
  type: 'bar',
  data: {
    labels: [],
    datasets: []
  },
  options: {
    defaultFontFamily: "'Open Sans', Arial, sans-serif",
    defaultFontSize: 13,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      mode: 'nearest',
      displayColors: false,
      backgroundColor: '#fbfbfb',
      titleFontColor: '#333',
      titleMarginBottom: 8,
      xPadding: 8,
      yPadding: 8,
      bodySpacing: 6,
      callbacks: {
        label(tooltipItem, data) {
          const i = tooltipItem.datasetIndex;

          return data.datasets[i].label;
        },
        labelTextColor(tooltipItem, _chart) {
          return _chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor;
        }
      },
      itemSort(r, t) {
        return t.datasetIndex - r.datasetIndex;
      }
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 3,
            callback(value, index) {
              if (index === 0) {
                return '100%';
              }

              if (index === 1) {
                return '50%';
              }

              if (index === 2) {
                return `0%`;
              }

              return '';
            }
          }
        }
      ]
    }
  }
};

export default CHART_OPTIONS;
