import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Title.css';

const title = b.with('title');

const Title = ({ text }) => <h2 className={title()}>{text}</h2>;

Title.propTypes = {
  text: PropTypes.string.isRequired
};

export default Title;
