import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Description, Explanation, Title } from '../../Atoms';
import SCREENS from '../../../helpers/screens';
import { useSelectedOptions, useSetScreen, useSettings } from '../../../helpers/hooks';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';
import { getIsApartmentSelected } from '../../../redux/selectors';
import { HouseTypeControl } from '../../Controls';

const HouseTypeScreen = () => {
  const [houseType] = useSelectedOptions(SCREENS.HOUSE_TYPE__SCREEN);
  const { title, message, explanation, prevButton, nextButton } = useSettings(SCREENS.HOUSE_TYPE__SCREEN);
  const setPrevScreen = useSetScreen(SCREENS.PURPOSE__SCREEN);
  const isApartmentSelected = useSelector(getIsApartmentSelected);
  const nextScreen = isApartmentSelected ? SCREENS.NUMBER_OF_BUILDINGS__SCREEN : SCREENS.NUMBER_OF_UNITS__SCREEN;
  const setNextScreen = useSetScreen(nextScreen);

  return (
    <Screen>
      <ScreenContent>
        <Title text={title} />
        <HouseTypeControl />
        <Description text={message} />
        {explanation ? <Explanation title={explanation.title} message={explanation.message} /> : null}
      </ScreenContent>
      <ScreenButtons>
        <Button onClick={setPrevScreen} text={prevButton} outline id="prev-btn" />
        <Button onClick={setNextScreen} text={nextButton} disabled={!houseType} id="next-btn" />
      </ScreenButtons>
    </Screen>
  );
};

export default HouseTypeScreen;
