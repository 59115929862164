import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import b from 'b_';
import { useSelector } from 'react-redux';

import {
  FootprintControl,
  HouseTypeControl,
  NumberOfFloorsControl,
  NumberOfBuildingsControl,
  NumberOfUnitsControl,
  UnitSizeControl
} from '../../../Controls';
import { getIsApartmentSelected } from '../../../../redux/selectors';
import { useSetScreen, useSettings } from '../../../../helpers/hooks';
import SCREENS from '../../../../helpers/screens';
import { Button, Description, Popup } from '../../../Atoms';

import './ControlPanel.css';

const controlPanel = b.with('control-panel');

const ControlPanel = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const isApartmentSelected = useSelector(getIsApartmentSelected);
  const { controlPanelLabels, controlPanelConfirmationPopup } = useSettings(SCREENS.CHARTS__SCREEN);
  const setWelcomeScreen = useSetScreen(SCREENS.WELCOME__SCREEN);
  const showPopup = useCallback(() => {
    setPopupVisibility(true);
  }, []);
  const closePopup = useCallback(() => {
    setPopupVisibility(false);
  }, []);

  return (
    <div className={controlPanel()}>
      <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.HOUSE_TYPE__SCREEN]}</h2>
      <HouseTypeControl />
      {isApartmentSelected ? (
        <>
          <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.NUMBER_OF_BUILDINGS__SCREEN]}</h2>
          <NumberOfBuildingsControl />
        </>
      ) : (
        <>
          <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.NUMBER_OF_UNITS__SCREEN]}</h2>
          <NumberOfUnitsControl />
        </>
      )}
      <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.NUMBER_OF_FLOORS__SCREEN]}</h2>
      <NumberOfFloorsControl />
      {isApartmentSelected ? (
        <>
          <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.FOOTPRINT__SCREEN]}</h2>
          <FootprintControl />
        </>
      ) : (
        <>
          <h2 className={controlPanel('label')}> {controlPanelLabels[SCREENS.UNIT_SIZE__SCREEN]}</h2>
          <UnitSizeControl />
        </>
      )}
      <div className={controlPanel('start')}>
        <Button text="Start over" outline onClick={showPopup} id="start-over-btn" />
      </div>
      {isPopupVisible
        ? ReactDOM.createPortal(
            <Popup className={controlPanel('confirmation-popup')}>
              <Description text={controlPanelConfirmationPopup.text} />
              <div className={controlPanel('confirmation-buttons')}>
                <Button
                  text={controlPanelConfirmationPopup.okButton}
                  onClick={setWelcomeScreen}
                  id="start-over-btn-confirm"
                />
                <Button
                  text={controlPanelConfirmationPopup.cancelButton}
                  outline
                  onClick={closePopup}
                  id="start-over-btn-cancel"
                />
              </div>
            </Popup>,
            document.getElementById('root')
          )
        : null}
    </div>
  );
};

export default ControlPanel;
