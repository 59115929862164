import React from 'react';

import { Button, Description, Explanation, Title } from '../../Atoms';
import SCREENS from '../../../helpers/screens';
import { useSelectedOptions, useSetScreen, useSettings } from '../../../helpers/hooks';
import { Screen, ScreenContent, ScreenButtons } from '../Screen';
import { UnitSizeControl } from '../../Controls';

const UnitSizeScreen = () => {
  const { title, message, prevButton, nextButton, explanation } = useSettings(SCREENS.UNIT_SIZE__SCREEN);
  const [houseType] = useSelectedOptions(SCREENS.HOUSE_TYPE__SCREEN);
  const setPrevScreen = useSetScreen(SCREENS.NUMBER_OF_FLOORS__SCREEN);
  const setNextScreen = useSetScreen(SCREENS.COUNTRY__SCREEN);

  return (
    <Screen>
      <ScreenContent>
        <Title text={title[houseType] || title.def} />
        <UnitSizeControl />
        <Description text={message[houseType] || message.def} />
        {explanation ? <Explanation title={explanation.title} message={explanation.message} /> : null}
      </ScreenContent>
      <ScreenButtons>
        <Button onClick={setPrevScreen} text={prevButton} outline id="prev-btn" />
        <Button onClick={setNextScreen} text={nextButton} id="next-btn" />
      </ScreenButtons>
    </Screen>
  );
};

export default UnitSizeScreen;
